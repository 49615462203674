
import { defineComponent } from 'vue';
import ClearButtonVue from './ClearButton.vue';
import SortButtonsVue from './SortButtons.vue';
import Slider from '@vueform/slider';
import Toggle from '@vueform/toggle';

interface Pomieszczenie {
    pomieszczenie: string;
    powierzchnia: string;
}

enum ApartmentStatus {
    WOLNE = 'wolne',
    REZERWACJA = 'rezerwacja',
    SPRZEDANE = 'sprzedane',
    W_PRZYGOTOWANIU = 'w przygotowaniu'
}

interface Apartment {
    mieszkanie: number;
	kondygnacja: number;
	klatka: 'A' | 'B',
	powierzchnia: number;
	pomieszczenia: Pomieszczenie[];
	balkony: number[];
	tarasy: number[];
	'm-ce postojowe': string;
	'scianki dzialowe': number;
    wystawa: string;
	cena: number;
	promocja: string;
	status: ApartmentStatus
}
type Column = keyof Apartment;

type Apartments = Apartment[] | null | false;

enum SortDirection {
    AZ = 'az',
    ZA = 'za'
}

interface Data {
    apartments: Apartments;
    sort: {
        by: Column | null;
        direction: SortDirection;
    },
    filter: {
        klatka: 'A' | 'B' | null;
        powierzchnia: number[];
        kondygnacja: number[];
        pokoje: number[];
        tylkoDostepne: boolean;
    },
    mobileScreen: boolean;
}

const sum = (arr: number[]) => arr.reduce((total, item) => total + item, 0);

enum Validation {
    MIN_POWIERZCHNIA = 20,
    MAX_POWIERZCHNIA = 90,
    MIN_KONDYGNACJA = 0,
    MAX_KONDYGNACJA = 2,
    MIN_POKOJE = 1,
    MAX_POKOJE = 4,

    POWIERZCHNIA_STEP = 10
}

let timer: number | null = null;
const THROTTLE_INTERVAL = 250;
const MOBILE_LAYOUT_THRESHOLD = 960;

enum DirectionToTip {
    N = 'północ',
    NE = 'północny wschód',
    E = 'wschód',
    SE = 'południowy wschód',
    S = 'południe',
    SW = 'południowy zachód',
    W = 'zachód',
    NW = 'północny zachód'
}

export default defineComponent({
    SortDirection,
    Validation,
    ApartmentStatus,
    DirectionToTip,

    name: 'apartment-table',
    components: {
        'sort-buttons': SortButtonsVue,
        'clear-button': ClearButtonVue,
        Slider,
        Toggle
    },
    data (): Data {
        return {
            apartments: null,
            sort: {
                by: null,
                direction: SortDirection.AZ
            },
            filter: {
                klatka: null,
                powierzchnia: [ Validation.MIN_POWIERZCHNIA, Validation.MAX_POWIERZCHNIA ],
                kondygnacja: [ Validation.MIN_KONDYGNACJA, Validation.MAX_KONDYGNACJA ],
                pokoje: [ Validation.MIN_POKOJE, Validation.MAX_POKOJE ],
                tylkoDostepne: false
            },

            mobileScreen: false
        };
    },
    computed: {
        directionTip () {
            return (direction: string) => direction.split(' / ').map((dir) => DirectionToTip[dir as keyof typeof DirectionToTip]).join(' / ');
        },
        filteredApartments (): Apartments {
            if (!this.apartments) {
                return this.apartments;
            }
            const apartments = [ ...this.apartments ];
            let [ min, max ] = this.filter.powierzchnia;
            if (min === max) {
                min = Math.max(Validation.MIN_POWIERZCHNIA, this.filter.powierzchnia[0] - Validation.POWIERZCHNIA_STEP / 2);
                max = min === Validation.MIN_POWIERZCHNIA
                    ? Validation.MIN_POWIERZCHNIA + Validation.POWIERZCHNIA_STEP
                    : Math.min(Validation.MAX_POWIERZCHNIA, this.filter.powierzchnia[1] + Validation.POWIERZCHNIA_STEP / 2);
            }
            const filteredByPowierzchnia = apartments.filter((apt) => min <= apt.powierzchnia && apt.powierzchnia <= max);
            
            const filteredByKondygnacja = filteredByPowierzchnia.filter((apt) => this.filter.kondygnacja[0] <= apt.kondygnacja && apt.kondygnacja <= this.filter.kondygnacja[1]);
            
            const filteredByKlatka = this.filter.klatka != null
                ? filteredByKondygnacja.filter((apt) => apt.klatka === this.filter.klatka)
                : filteredByKondygnacja;

            const filteredByPokoje = filteredByKlatka.filter((apt) => {
                const pokoje = apt.pomieszczenia.filter((pom) => pom.pomieszczenie.startsWith('pokój')).length;
                return this.filter.pokoje[0] <= pokoje && pokoje <= this.filter.pokoje[1];
            });

            const filteredByAvailability = this.filter.tylkoDostepne
                ? filteredByPokoje.filter((apt) => apt.status === ApartmentStatus.WOLNE)
                : filteredByPokoje;

            return filteredByAvailability.sort((apt1, apt2) => {
                switch (this.sort.by) {
                    case 'balkony': {
                        const sum1 = sum([ ...(apt1.balkony || []), ...(apt1.tarasy || []) ]);
                        const sum2 = sum([ ...(apt2.balkony || []), ...(apt2.tarasy || []) ]);
                        return this.sort.direction === SortDirection.AZ
                            ? (sum1 < sum2 ? -1 : 1)
                            : (sum1 < sum2 ? 1 : -1);
                    }
                    case 'pomieszczenia': {
                        const pom1 = apt1.pomieszczenia.filter((pom) => pom.pomieszczenie.startsWith('pokój')).length;
                        const pom2 = apt2.pomieszczenia.filter((pom) => pom.pomieszczenie.startsWith('pokój')).length;
                        return this.sort.direction === SortDirection.AZ
                            ? (pom1 < pom2 ? -1 : 1)
                            : (pom1 < pom2 ? 1 : -1);
                    }
                    case 'mieszkanie':
                    case 'klatka':
                    case 'kondygnacja':
                    case 'powierzchnia':
                    default:
                        return this.sort.direction === SortDirection.AZ
                            ? (apt1[this.sort.by as Column] < apt2[this.sort.by as Column] ? -1 : 1)
                            : (apt1[this.sort.by as Column] < apt2[this.sort.by as Column] ? 1 : -1);
                }
            });
        },
        availableCount (): number {
            return this.filteredApartments
                ? this.filteredApartments
                    .filter((apartment) => apartment.status === ApartmentStatus.WOLNE)
                    .length
                : 0;
        }
    },
    async created () {
        const folder = process.env.NODE_ENV === 'production'
            ? '/wp-content/uploads/tabela-lokali/'
            : './';
		const now = new Date().getTime();
        try {
            const response = await fetch(`${folder}apartments.json?a=${now}`);
            this.apartments = await response.json();
        } catch (e) {
            console.log(e);
            this.apartments = false;
        }
    },
    mounted () {
        this.rescale();
        window.addEventListener('resize', this.onResize);
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onSort (column: Column, direction: SortDirection) {
            this.sort.by = column;
            this.sort.direction = direction;
        },
        onResize () {
            if (!timer) {
                timer = setTimeout(this.rescale, THROTTLE_INTERVAL);
            }
        },
        rescale () {
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            this.mobileScreen = window.innerWidth <= MOBILE_LAYOUT_THRESHOLD;
        }
    }
});
