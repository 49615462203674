
import { defineComponent } from 'vue';
import ApartmentTable from './components/Table.vue';

export default defineComponent({
    name: 'App',
    components: {
        ApartmentTable
    }
});
