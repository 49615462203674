
import { defineComponent } from 'vue';

enum SortDirection {
    AZ = 'az',
    ZA = 'za'
}

export default defineComponent({
    SortDirection,

    name: 'sort-buttons',
    emits: [ 'sort' ],
    methods: {
        onClick (direction: SortDirection) {
            this.$emit('sort', direction);
        }
    }
})
